import { sendAccountApiKeyCreatedMessage } from './../../../server/src/services/email-controller';
import {Component, inject, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {LoginService} from "../services/login.service";
import {UserData} from "../model";
import {Observable, finalize, forkJoin, timer} from "rxjs";
import { EmailService } from '../services/email.service';
import { LocalizationService } from '../shared/localization/localization.service';

class MessagePurpose {
  id = "";
  label = "";

  // tslint:disable-next-line:variable-name
  constructor(_id: string, _label: string) {
    this.id = _id;
    this.label = _label;
  }
}

@Component({
  selector: 'modal-contact',
  templateUrl: './dialog-contact.component.html',
  styleUrls: ['./dialog-contact.component.scss']
})
export class DialogContactComponent implements OnInit {
  private readonly localizationService = inject(LocalizationService);

  @Input() idModal: string|null = null;
  @Input() callback: ((formData: any) => Observable<any>) | null = null;
  @Input() topic = "technical";

  nextId = 0;
  purposes: MessagePurpose[] = [ 
    new MessagePurpose("technical", this.localizationService.localize("dialog.contact.us.purpose.technical")), 
    new MessagePurpose("commercial", this.localizationService.localize("dialog.contact.us.purpose.commercial")), 
    new MessagePurpose("other", this.localizationService.localize("dialog.contact.us.purpose.other")), 
  ];

  contactForm: FormGroup = new FormGroup({
    company: new FormControl(''),
    name: new FormControl(''),
    email: new FormControl('', [Validators.required, Validators.email]),
    purpose: new FormControl('technical'),
    message: new FormControl('', [Validators.required])
  })
  contactFailed = false;
  contactSent = false;
  sending = false;

  constructor(private loginService: LoginService, private emailService: EmailService) {
    if (this.idModal == null) { this.idModal = `modal-contact-${this.nextId++}`; }
  }

  isCompanyInvalid(): boolean {
    return false;
  }

  isNameInvalid(): boolean {
    return false;
  }

  isEmailInvalid(): boolean{
    return false;
  }
  isEmailEmpty(): boolean {
    return false;
  }
  isEmailFormatInvalid(): boolean{
    return false;
  }

  ngOnInit(): void {
    const userData = this.loginService.getUserData() as UserData;
    if (userData){
      this.contactForm.get('email')?.setValue(userData.email);
      this.contactForm.get('name')?.setValue(userData.name);
      this.contactForm.get('company')?.setValue(userData.company);
    }
    if (this.topic) {
      this.contactForm.get('purpose')?.setValue(this.topic);
    }
  }

  reset(): void{
    this.contactSent = false;
    this.contactFailed = false;
    this.contactForm.get('message')?.patchValue('')
    this.ngOnInit();
  }

  submit(): void {
    if (this.contactForm.get('message')?.errors != null) { return; }
    // if (!this.callback) { return; }

    const email = this.contactForm.get('email')?.value;
    const name = this.contactForm.get('name')?.value;
    const company = this.contactForm.get('company')?.value;
    const purpose = this.contactForm.get('purpose')?.value;
    const message = this.contactForm.get('message')?.value;

    this.sending = true;
    forkJoin([
      this.emailService.contactMessage({email, name, company, purpose, message}),
      this.emailService.contactMessageInternal({email, name, company, purpose, message})
    ]).pipe(
      finalize(() => {
        this.sending = false
      })
    ).subscribe((result:{status:string}[]) => {
        if (result && result.every(r => r.status === "OK")) {
          this.contactSent = true;
          this.contactFailed = false;
        } else {
          this.contactSent = false;
          this.contactFailed = true;
        }
      })




    // const callbackReturn: Observable<any>|null = this.callback({
    //   email,
    //   name,
    //   company,
    //   purpose,
    //   message
    // });
    // if (callbackReturn?.subscribe) {
    //   callbackReturn.subscribe((data: any) => {
    //     if (data && data.status === "OK") {
    //       this.contactSent = true;
    //       this.contactFailed = false;
    //     } else {
    //       this.contactSent = false;
    //       this.contactFailed = true;
    //     }
    //   });
    // }
  }

}
