import { DialogUtilsService } from './../shared/data/services/dialog-utils.service';
import { artistQuery } from './../shared/store/artist/artist.selectors';
import { MatTableDataSource } from '@angular/material/table';
import { Component, ViewChild, OnChanges, inject, OnInit, AfterViewInit } from '@angular/core';
import { ApiError, Artist} from "../model";
import { ArtistService} from "../services/artist.service";
import { PageEvent } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSort, Sort } from '@angular/material/sort';
import { Observable, Subject, combineLatest, filter, map, takeUntil, tap } from 'rxjs';
import { ImageService } from '../services/image.service';

import {remove as removeDiacritics} from 'diacritics';
import { Store } from '@ngrx/store';
import { artistActions } from '../shared/store/artist/artist.actions';
import { Skeleton, Status } from '../shared/store/state';
import { FeedbackContext } from '../dialog-feedback/dialog-feedback.component';

interface DisambigResult {
  skeletons: Skeleton[]|undefined,
  topResultsArtists: Artist[],
  otherResultsArtists: Artist[],
  noMatch: boolean
}


@Component({
  templateUrl: './app-disambig.component.html',
  styleUrls: ['./app-disambig.component.scss']
})
export class AppDisambigComponent  implements OnInit, AfterViewInit  {
  store = inject(Store)
  artistService = inject(ArtistService)
  imageService = inject(ImageService)
  router = inject(Router)
  route = inject(ActivatedRoute)
  dialogUtilsService = inject(DialogUtilsService)

  topResultsArtists!: Artist[];
  otherResultsArtists!: Artist[];
  otherResultsDatasource = new MatTableDataSource<Artist>();

  @ViewChild(MatSort) matSort!: MatSort;

  disambigView = "grid";

  listViewProps = {
    columns: ['name', 'type', 'nationality', 'comments'],
    handlePaginatoreEvent: (event: PageEvent) => { console.log(event)}
  }

  destroy$: Subject<void> = new Subject<void>();

  disambig$ = this.store.select(artistQuery.selectArtistsDisambiguation)
  disambigStatus$ = this.store.select(artistQuery.selectStatus);
  disambigError$ = this.store.select(artistQuery.selectError);
  searchParams$ = this.route.params.pipe(
    takeUntil(this.destroy$),
    map(params => (params['id']))
  )

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit(){
    this.route.params.pipe(
      map(params => params['searchTerm']),
      filter(searchTerm => searchTerm !== undefined && searchTerm !== null && searchTerm !== ""),
      map(searchTerm => this.store.dispatch(artistActions.getartistsbyname({name: searchTerm}))),
    ).subscribe()
  }

  ngAfterViewInit() {
    this.otherResultsDatasource.sort = this.matSort;
  }


  artistsResults$: Observable<DisambigResult> =
    combineLatest([this.disambig$,this.disambigError$]).pipe(
      map(([{artists, searchTerm}, error]) => {
        if(error === ApiError.NO_MATCH) return {skeletons: [], topResultsArtists: [], otherResultsArtists: [], noMatch: true}
        if(!(artists[0] as Skeleton).skeleton) {
          return this.splitTopArtists(artists as Artist[], searchTerm)
        } else {
          return ({skeletons: artists as Skeleton[], topResultsArtists: [], otherResultsArtists: [], noMatch: false})
        }
      }),
      tap(({otherResultsArtists}) => {
        this.otherResultsDatasource.data = otherResultsArtists;
      })
    )

  splitTopArtists(artists: Artist[], searchTerm: string|null): DisambigResult {
    const hasSameName = (artist:Artist) => {
      return searchTerm !== null ? removeDiacritics(artist.name?.toLowerCase()) === removeDiacritics(searchTerm.toLowerCase()||''): false
    };
    const hasNationality = (artist:Artist) => { return  artist.nationality && artist.nationality !== null};
    const hasCover = (artist:Artist) => {return  artist.cover && artist.cover !== null};

    let topResultsArtists = artists.filter((artist: Artist) => hasSameName(artist) && hasNationality(artist) && hasCover(artist))
    if(topResultsArtists.length === 0 ) {
      topResultsArtists = artists.filter((artist: Artist) => (hasSameName(artist) && hasCover(artist)) || (hasSameName(artist) && hasNationality(artist)) )
    }
    if(topResultsArtists.length === 0 ) {
      topResultsArtists = artists.filter((artist: Artist) => hasCover(artist) && (artist.popularity && artist.popularity > 0))
    }

    topResultsArtists = topResultsArtists.sort((a1: Artist, a2: Artist) => {
      if(!a1.popularity || !a2.popularity) return 0;
      return a2.popularity - a1.popularity;
    });
    topResultsArtists = topResultsArtists.slice(0,5);
    const otherResultsArtists = artists.filter((artist: Artist) => !topResultsArtists.includes(artist))

    return {skeletons: undefined, topResultsArtists, otherResultsArtists, noMatch: false}
  }

  artistHasIds(artist: Artist): boolean {
    return this.artistService.getIdentifier(artist);
  }

  isListView(): boolean{
    return this.disambigView === "list";
  }

  isGridView(): boolean {
    return this.disambigView === "grid";
  }

  setViewToList(): void{
    this.disambigView = "list";
  }
  setViewToGrid(): void{
    this.disambigView = "grid"
  }

  openFeedbackDialog(){
    this.dialogUtilsService.openFeedbackDialog(null)
  }

}
