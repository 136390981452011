<div class="table-controls">
  <mat-form-field class="filter">
    <mat-label>{{'table.controls.filter'|loc}}</mat-label>
    <input matInput [(ngModel)]="filterValue" (keyup)="applyFilter($event)" placeholder="" #input>
  </mat-form-field>
  <span class="filtered-data">
    {{filteredDataCount}} / {{totalDataCount}}
  </span>
  <mat-paginator [pageSizeOptions]="[10, 20, 100]" showFirstLastButtons [attr.aria-label]="'table.controls.select.page'|loc"></mat-paginator>
</div>
