
<div class="mat-typography">
  <h1 mat-dialog-title>{{'dialog.terms.title.1'|loc}}</h1>
  <mat-dialog-content>
    <p>{{'dialog.terms.paragraph.1'|loc}}</p>

    <h2>{{'dialog.terms.title.2'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.2'|loc}}</p>
    <p>{{'dialog.terms.paragraph.3'|loc}}</p>

    <h2>{{'dialog.terms.title.3'|loc}}</h2>
    <p>
      {{'dialog.terms.paragraph.4.1'|loc}}
      <a class="link" href="javascript:false;" (click)="showPrivacyPolicyDialog()">{{'dialog.privacy.policy.title.1'|loc}}</a> 
      {{'dialog.terms.paragraph.4.2'|loc}}
    </p>
    <h2>{{'dialog.terms.title.4'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.5'|loc}}</p>
    <ul>
      <li>{{'dialog.terms.list.1.1'|loc}}</li>
      <li>{{'dialog.terms.list.1.2'|loc}}</li>
      <li>{{'dialog.terms.list.1.3'|loc}}</li>
      <li>{{'dialog.terms.list.1.4'|loc}}</li>
      <li>{{'dialog.terms.list.1.5'|loc}}</li>
    </ul>
    <p>{{'dialog.terms.paragraph.6'|loc}}</p>

    <h2>{{'dialog.terms.title.5'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.7'|loc}}</p>

    <h2>{{'dialog.terms.title.6'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.8'|loc}}</p>

    <h2>{{'dialog.terms.title.7'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.9'|loc}}</p>

    <h2>{{'dialog.terms.title.8'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.10'|loc}}</p>

    <h2>{{'dialog.terms.title.9'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.11'|loc}}</p>

    <h2>{{'dialog.terms.title.10'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.12'|loc}}</p>

    <h2>{{'dialog.terms.title.11'|loc}}</h2>
    <p>{{'dialog.terms.paragraph.13'|loc}}</p>
    <p><a  class="link" href="javascript:false;" (click)="showContactUsDialog()">{{'menu.contact'|loc}}</a></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'|loc}}</button>
  </mat-dialog-actions>
</div>
