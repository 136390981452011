import { Component, inject } from '@angular/core';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';

@Component({
  selector: 'dialog-policy',
  templateUrl: './dialog-policy.component.html',
  styleUrls: ['./dialog-policy.component.css']
})
export class DialogPolicyComponent{
  private readonly dialogUtils = inject(DialogUtilsService);

  showTermsAndConditionsDialog() {
    this.dialogUtils.openTermsConditionsDialog(null);
  }
  showContactUsDialog(){
    this.dialogUtils.openContactUsDialog(null);
  }
}
