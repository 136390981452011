import { Component, inject } from '@angular/core';
import { DialogUtilsService } from '../shared/data/services/dialog-utils.service';

@Component({
  selector: 'dialog-terms',
  templateUrl: './dialog-terms.component.html',
  styleUrls: ['./dialog-terms.component.css']
})
export class DialogTermsComponent {
  private readonly dialogUtils = inject(DialogUtilsService);

  showPrivacyPolicyDialog() {
    this.dialogUtils.openPrivacyPolicyDialog(null);
  }
  showContactUsDialog(){
    this.dialogUtils.openContactUsDialog(null);
  }
}
