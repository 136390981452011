<div class="entity-page">
  <div class="name-block">
    <h1 loc="release"></h1>
    <div class="name">
      <h1><skeleton shape="small"></skeleton></h1>
    </div>
  </div>
  <div class="info">
    <div class="picture">
      <div>
        <skeleton shape="circle"></skeleton>
      </div>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="information">
      <h2 loc="information"></h2>
      <div><span class="strong">{{'release.type'|loc}}</span> <skeleton shape="small"></skeleton></div>
      <div><span class="strong">{{'release.year.of.recording'|loc}}</span> <skeleton shape="tiny"></skeleton></div>
      <div><span class="strong">{{'release.label'|loc}}</span> <skeleton shape="tiny"></skeleton></div>
    </div>
    <div class="identifiers">
      <h2 loc="identifiers"></h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">UPC:</span> <skeleton shape="small"></skeleton></div>
      </div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab [label]="'contributors'|loc">
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="recordings"></div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
