import { Component, HostBinding, inject, Input, OnChanges, SimpleChanges, ViewEncapsulation } from "@angular/core";
import { take, tap } from "rxjs";
import { LocalizationService, SupportedLocale } from "src/app/shared/localization/localization.service";

@Component({
    selector: 'lang-selector',
    template: `
        <div class="lang-selector" *ngIf="currentLocale$|async as currentLocale" (keypress)="toggleLocale()">
            <ng-container *ngIf="currentLocale === SupportedLocale.EN">
                <button mat-flat-button disabled >English</button> 
                | 
                <button mat-flat-button class="link" (click)="loadLocale(SupportedLocale.FR_CA)" >Français</button>
            </ng-container>
            <ng-container *ngIf="currentLocale === SupportedLocale.FR_CA">
                <button mat-flat-button class="link" (click)="loadLocale(SupportedLocale.EN)"> English </button> 
                | 
                <button mat-flat-button disabled>Français</button>
            </ng-container>
        </div>
    `,
    styles: [`
        .lang-selector span {
            font-size: 14px;
            padding-inline: 16px
        }
    `]
})
export class LangSelectorComponent implements OnChanges{
    readonly localizationService = inject(LocalizationService)
    @Input('toggle') toggle: boolean = false

    currentLocale$ = this.localizationService.currentLocale$.asObservable()

    SupportedLocale = SupportedLocale

    ngOnChanges(changes: SimpleChanges): void {
        if(changes['toggle']?.currentValue) {
            this.toggleLocale();
        }
    }

    loadLocale(locale: SupportedLocale) {
        this.localizationService.loadLocale(locale).pipe(
            take(1),
            tap(locale => this.localizationService.saveCurrentLocale(locale)),
            tap(() => window.location.reload())
        ).subscribe()
    }

    toggleLocale() {
        const currentLocale = this.localizationService.currentLocale$.value
        if(currentLocale == SupportedLocale.EN) {
            this.loadLocale(SupportedLocale.FR_CA)
        }
        if(currentLocale == SupportedLocale.FR_CA) {
            this.loadLocale(SupportedLocale.EN)
        }
    }
}
