import { XAPIWork, Work, XAPIRecording, RecordingOfWork } from "src/app/model";

export const toWorkFromXApiWork = (xwork: XAPIWork):Work => {
  return {
    iswc: xwork.iswc,
    bowi: xwork.bowi,
    entityType: 'work',
    title: xwork.title,
    subtitle: xwork.subtitle,
    role: xwork.role,
    contributors: (xwork.contributors || [])
  } as Work;
}

