<div class="identifier thin">
  <ng-container *ngIf="values != null">
    <span *ngIf="links.length > 0; then link else noLink"></span>
    <ng-template #link>
      <a href="{{links[0]}}" target="_blank" [attr.aria-label]="'aria.navigate.to.source'|loc">{{values[0]}}</a>
    </ng-template>
    <ng-template #noLink>{{values[0]}}</ng-template>

    <copy-to-clipboard [value]="values[0]"></copy-to-clipboard>

    <a *ngIf="values.length > 1" class="additional-ids-link link" 
      (click)="openAdditionalIdsDialog()" (keypress)="openAdditionalIdsDialog()"
      [attr.aria-label]="'aria.modal.additional.ids'|loc" tabindex="0">+{{values.length -1 }}</a>
  </ng-container>
</div>

