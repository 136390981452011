import { Injectable, inject } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { combineLatest, of, take } from "rxjs";
import { DialogContactComponent } from "src/app/dialog-contact/dialog-contact.component";
import { DialogFeedbackComponent, FeedbackContext, FeedbackContextData } from "src/app/dialog-feedback/dialog-feedback.component";
import { DialogHelpComponent } from "src/app/dialog-help/dialog-help.component";
import { DialogPolicyComponent } from "src/app/dialog-policy/dialog-policy.component";
import { DialogTermsComponent } from "src/app/dialog-terms/dialog-terms.component";
import { releaseQuery } from "../../store/release/release.selectors";
import { Store } from "@ngrx/store";
import { recordingQuery } from "../../store/recording/recording.selectors";
import { workQuery } from "../../store/work/work.selectors";
import { artistQuery } from "../../store/artist/artist.selectors";
import { Artist } from "src/app/model";
import { ActivatedRoute, Router } from "@angular/router";
import { DialogRef } from "@angular/cdk/dialog";
import { MatMenuTrigger } from "@angular/material/menu";

@Injectable({
  providedIn: 'root'
})
export class DialogUtilsService {
  dialog = inject(MatDialog);
  store = inject(Store);
  route = inject(ActivatedRoute);
  router = inject(Router);

  openContactUsDialog(menuTrigger: MatMenuTrigger|null): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogContactComponent, {
      panelClass: "dialog",
      restoreFocus: false,
    });
   this.restoreFocus(dialogRef, menuTrigger);
  }

  openPrivacyPolicyDialog(menuTrigger: MatMenuTrigger|null): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogPolicyComponent, {
      panelClass: "dialog",
      autoFocus: false,
      restoreFocus: false,
    });
    this.restoreFocus(dialogRef, menuTrigger);
  }

  openTermsConditionsDialog(menuTrigger: MatMenuTrigger|null): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogTermsComponent, {
      panelClass: "dialog",
      autoFocus: false,
      restoreFocus: false,
    });
    this.restoreFocus(dialogRef, menuTrigger);
  }

  openHelpDialog(menuTrigger: MatMenuTrigger|null): void {
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(DialogHelpComponent, {
      panelClass: "dialog",
      restoreFocus: false,
    });
    this.restoreFocus(dialogRef, menuTrigger);
  }

  openFeedbackDialog(menuTrigger: MatMenuTrigger|null): void {
    this.dialog.closeAll();
    combineLatest([
      of(this.router.routerState.snapshot.url),
      this.store.select(artistQuery.selectArtist),
      this.store.select(artistQuery.selectArtistsDisambiguation),
      this.store.select(releaseQuery.selectRelease),
      this.store.select(recordingQuery.selectRecording),
      this.store.select(workQuery.selectWork),
    ])
    .pipe(take(1))
    .subscribe({
      next: ([url, artist, disambig, release, recording, work]) => {
        let data:FeedbackContextData = {context: FeedbackContext.UNKNOWN};
        if(artist && url.includes('app-party')) data = {context: FeedbackContext.ARTIST, artist: {id: artist?.id, name: artist?.name}}
        if(disambig && url.includes('app-disambig')) data = {context: FeedbackContext.DISAMBIG,
          disambig: {searchTerm: disambig.searchTerm||'', artists: (disambig.artists as Artist[])||[]}
        }
        if(release && url.includes('app-release')) data = {context: FeedbackContext.RELEASE, release: {upc: release?.upc, title: release?.title}}
        if(recording && url.includes('app-recording')) data = {context: FeedbackContext.RECORDING, recording: {isrc: recording?.isrc, title: recording?.title}}
        if(work && url.includes('app-work')) data = {context: FeedbackContext.WORK, work: {iswc: work?.iswc, title: work?.title}}
        const dialogRef = this.dialog.open(DialogFeedbackComponent, { panelClass: "dialog", data, restoreFocus: menuTrigger === null})
        if(menuTrigger !== null) this.restoreFocus(dialogRef, menuTrigger);
      }
    })
  }

  private restoreFocus(dialogRef: MatDialogRef<any, any>, menuTrigger: MatMenuTrigger|null) {
    dialogRef.afterClosed().pipe(take(1)).subscribe(() => {
      menuTrigger?.focus();
    })
  }
}
