<div class="stats" [ngClass]="{'hidden': stats == null}">
  <div class="stats-title"><counter [digit]="stats?.totalAssets || null"></counter> {{'stats.music.identifiers'|loc}}</div>
  <div>
      <div class="stats-element">
        <counter [digit]="stats?.parties || null" class="bold"></counter>
        {{'stats.artists'|loc}}
      </div>
      <div class="stats-element">
        <counter [digit]="stats?.partyIds || null" class="bold"></counter>
        {{'stats.artists.identifiers'|loc}}
      </div>
      <div class="stats-element">
        <counter [digit]="stats?.recordings || null" class="bold"></counter>
        {{'stats.recordings'|loc}}
      </div>
      <div class="stats-element">
        <counter [digit]="stats?.releases || null" class="bold"></counter>
        {{'stats.releases'|loc}}
      </div>
      <div class="stats-element">
        <counter [digit]="stats?.works || null" class="bold"></counter>
        {{'stats.works'|loc}}
      </div>
  </div>
</div>
