import { inject, Injectable } from "@angular/core";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { LocalizationService } from "../localization/localization.service";

@Injectable()
export class CustomMatPaginatorIntl extends MatPaginatorIntl {
  private readonly localizationService = inject(LocalizationService);

  override itemsPerPageLabel = this.localizationService.localize('table.controls.paginator.itemsPerPageLabel');
  override nextPageLabel     = this.localizationService.localize('table.controls.paginator.nextPageLabel');
  override previousPageLabel = this.localizationService.localize('table.controls.paginator.previousPageLabel');
  override firstPageLabel    = this.localizationService.localize('table.controls.paginator.firstPageLabel');
  override lastPageLabel     = this.localizationService.localize('table.controls.paginator.lastPageLabel');

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ?
      Math.min(startIndex + pageSize, length) :
      startIndex + pageSize;
    return this.localizationService.localize('table.controls.paginator.rangeLabel', (startIndex + 1).toFixed(), endIndex.toFixed(), length.toFixed());
  }
}