import { Injectable, inject } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { LocalizationService } from '../../localization/localization.service';
import { Artist, Entity, EntityType, Recording, Release, Work } from './../../../model';

@Injectable({
  providedIn: 'root'
})
export class TitleService {
  private readonly titleService:Title = inject(Title);
  private readonly localizationService = inject(LocalizationService);

  private getLocalizedTitle() {
    return [
      this.localizationService.localize('home.data.explorer'),
      this.localizationService.localize('home.data.explorer.by'),
      this.localizationService.localize('home.data.explorer.quansic')
    ].join(' ');
  }

  changeBrowserTitle(entityType: EntityType, entity: Entity|null): void {
    let title = this.getLocalizedTitle();
    if(entity !== null) {
      switch(entityType){
        case 'party':
          title = `${(entity as Artist).name} - ${title}`; break;
        case 'recording':
          title = `${(entity as Recording).title} - ${title}`; break;
        case 'release':
          title = `${(entity as Release).title} - ${title}`; break;
        case 'work':
          title = `${(entity as Work).title} - ${title}`; break;
      }
    }
    this.titleService.setTitle(title);
  }

  initializeBrowserTitle(): void {
    this.titleService.setTitle(this.getLocalizedTitle());
  }
}
