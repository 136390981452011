<table-controls [totalDataCount]="dataSource.data.length"></table-controls>
<ng-container *ngIf="status$|async as status">
  <div *ngIf="status === 'loading' || status === 'idle'">
    <section>
      <mat-progress-bar
          color="accent"
          mode="determinate"
          [value]="recordingsProgress">
      </mat-progress-bar>
    </section>
    <table-recordings-skeleton></table-recordings-skeleton>
  </div>
  <div *ngIf="status === 'error'">
    There has been a problem loading the recordings. Please try again.
  </div>

  <div *ngIf="status === 'success'">
    <table-empty *ngIf="dataSource.data.length == 0" entity="recording"></table-empty>
    <div [ngClass]="{'hidden': dataSource.data.length == 0}" >

      <ng-container *ngIf="contributorsStatus$|async as contributorsStatus">
      <mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData(sort)" [trackBy]="trackByIsrc">
        <ng-container matColumnDef="audio" >
          <mat-header-cell *matHeaderCellDef> {{'recording.audio'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <audio-player *ngIf="recording.audioUrl != '' && recording.audioUrl != null" format="mini" [audioUrl]="recording.audioUrl" [trackIsrc]="recording.isrc" [trackName]="recording.title"></audio-player>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="crtc" *ngIf="crtcService.isCrtcUser()">
          <mat-header-cell *matHeaderCellDef> {{'recording.maipl'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <maipl-score [score]="crtcMaiplScore$(recording)|async"></maipl-score>
            <cancon-flag [isCancon]="recording.crtcMaiplStatus?.isCanadianContent"></cancon-flag>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="q2" *ngIf="q2">
          <mat-header-cell *matHeaderCellDef mat-sort-header="q2Score"> Q2 </mat-header-cell>
          <mat-cell *matCellDef="let recording">{{recording.q2Score}}</mat-cell>
        </ng-container>
        <ng-container matColumnDef="isrc" >
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by ISRC"> {{'recording.isrc'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <skeleton shape="small" *ngIf="recording.skeleton"></skeleton>
            <span *ngIf="!recording.skeleton">{{recording.isrc}}</span>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by title"> {{'recording.title'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <skeleton shape="autosm" *ngIf="recording.skeleton"></skeleton>
            <div *ngIf="!recording.skeleton">
              <a [routerLink]="['/app-recording', recording.isrc]">{{recording.title}} </a>
              <em class="comments">{{recording.subtitle}}</em>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="year" >
          <mat-header-cell *matHeaderCellDef mat-sort-header>{{'recording.year'|loc}} </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <skeleton shape="small" *ngIf="recording.skeleton"></skeleton>
            <div *ngIf="!recording.skeleton">{{recording.year}}</div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="artist" >
          <mat-header-cell *matHeaderCellDef mat-sort-header>
            {{'recording.main.artists'|loc}}
          </mat-header-cell>
          <mat-cell *matCellDef="let recording">
            <skeleton shape="small"
              *ngIf="recording.skeleton
                || (contributorsStatus === 'loading'
                      && recording['mainArtists'] === undefined
                      && recording['performers'] === undefined)"></skeleton>
            <div *ngIf="!recording.skeleton && recording['mainArtists'] !== undefined">
              <div *ngFor=" let artist of recording.mainArtists" class="mainArtists">
                <party-link [party]="artist" ></party-link>
              </div>
            </div>
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="columns"></mat-header-row>
        <mat-row matRipple *matRowDef="let client; columns: columns;" ></mat-row>
      </mat-table>
      </ng-container>
    </div>
  </div>
</ng-container>
