
<div class="mat-typography">
  <h1 mat-dialog-title>{{'dialog.help.title.1'|loc}}</h1>
  <div mat-dialog-content>
    <h2>{{'dialog.help.title.2'|loc}}</h2>
    <p>{{'dialog.help.paragraph.1'|loc}}</p>
    <ul class="list-unstyled">
      <li>{{'dialog.help.list.1.1'|loc}}</li>
      <li>{{'dialog.help.list.1.2'|loc}}</li>
      <li>{{'dialog.help.list.1.3'|loc}}</li>
    </ul>

    <p>{{'dialog.help.paragraph.2'|loc}}</p>
    <h2>{{'dialog.help.title.3'|loc}}</h2>
    <p>{{'dialog.help.paragraph.3'|loc}}</p>
    <p>{{'dialog.help.paragraph.4'|loc}}</p>
    <p>{{'dialog.help.paragraph.5'|loc}}</p>
    <p>{{'dialog.help.paragraph.6'|loc}}</p>

    <h2>{{'dialog.help.title.4'|loc}}</h2>
    <p>{{'dialog.help.paragraph.7'|loc}}</p>
    <p>{{'dialog.help.paragraph.8'|loc}}</p>
    <p>{{'dialog.help.paragraph.4'|loc}}</p>

    <h2>{{'dialog.help.title.5'|loc}}</h2>
    <p>{{'dialog.help.paragraph.10'|loc}}</p>
    <p>{{'dialog.help.paragraph.8'|loc}}</p>

    <h2>{{'dialog.help.title.6'|loc}}</h2>
    <p>{{'dialog.help.paragraph.12'|loc}}</p>

    <h2>{{'dialog.help.title.7'|loc}}</h2>
    <p>{{'dialog.help.paragraph.13'|loc}}</p>
    <p>{{'dialog.help.paragraph.14'|loc}}</p>
    <p>{{'dialog.help.paragraph.15'|loc}}</p>

    <p style="padding-left: 0px">{{'dialog.help.paragraph.16'|loc}}</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'|loc}}</button>
  </div>
</div>
