import { inject, Pipe } from "@angular/core";
import { LocalizationService } from "./localization.service";

@Pipe({
    name: 'loc'
})
export class LocalizationPipe{
    localizationService = inject(LocalizationService)

    transform(value: string, ...args: string[]){
        return this.localizationService.localize(value, ...args);
    }
}
