<div class="entity-page">
  <div class="name-block">
    <h1 loc="artist"></h1>
    <div class="name">
      <h1><skeleton shape="small" ></skeleton></h1>
      <em class="comments"><skeleton shape="tiny" ></skeleton></em>
    </div>
  </div>
  <div class="info">
    <div class="link">
      <skeleton shape="circle"></skeleton>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="information">
      <h2 loc="information"></h2>
      <div><span class="strong" loc="artist.type"></span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong" loc="artist.nationality"></span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong" loc="artist.dateOfbirth"></span> <skeleton shape="tiny" ></skeleton></div>
      <div><span class="strong" loc="artist.dateOfDeath"></span> <skeleton shape="tiny" ></skeleton></div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list two-columns">
        <div class="identifier isni"><span class="strong" loc="artist.isni"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier ipi"><span class="strong" loc="artist.ipi"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier ipn"><span class="strong" loc="artist.ipn "></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier discogsId"><span class="strong" loc="artist.discogsId"></span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier musicbrainzId"><span class="strong" loc="artist.musicbrainzId"></span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier deezerId"><span class="strong" loc="artist.deezerId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier luminateId"><span class="strong" loc="artist.luminateId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier gracenoteId"><span class="strong" loc="artist.gracenoteId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier tmsId"><span class="strong" loc="artist.tmsId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier appleId"><span class="strong" loc="appleId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier spotifyId"><span class="strong" loc="artist.spotifyId"></span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier wikidataId"><span class="strong" loc="wikidataId"></span>  <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier amazonId"><span class="strong" loc="artist.amazonId"></span> <skeleton shape="tiny" ></skeleton></div>
        <div class="identifier mergedIsni"><span class="strong" loc="artist.merged.insi"></span> <skeleton shape="tiny" ></skeleton></div>
      </div>
    </div>
    <div class="spacer"></div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" disabled>
      <mat-tab position="1">
        <ng-template mat-tab-label>
          {{'artist.relationships'|loc}}
        </ng-template>
        <div>
          <relationships ></relationships>
          <nameVariants ></nameVariants>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="releases"></div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="recordings"></div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="works"></div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
