import { Component, inject, Input, OnInit} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LocalizationService } from '../shared/localization/localization.service';

@Component({
  selector: 'copy-to-clipboard',
  templateUrl: './copy-to-clipboard.component.html',
  styleUrls: ['./copy-to-clipboard.component.css']
})
export class CopyToClipboardComponent implements OnInit {
  private readonly localizationService = inject(LocalizationService);
  @Input() value=""

  constructor(private _snackBar: MatSnackBar) { }

  ngOnInit(): void {
  }

  confirmCopiedToClipboard() {
    this._snackBar.open(
      this.localizationService.localize("copied.clipboard.success", this.value), 
      this.localizationService.localize("close"), 
      {
        duration: 5 * 1000,
        verticalPosition: 'bottom'
      }
    );
  }
}
