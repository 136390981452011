import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, catchError, map, Observable,tap, throwError } from "rxjs";
import { HttpClient } from "@angular/common/http";

export enum SupportedLocale {
  EN='en',
  FR='fr',
  FR_CA='fr_CA'
}
export type Locale = {
  locale: SupportedLocale,
  tokens: Token[]
}
export type Token = {
  id: string,
  value: string
}
export const DEFAULT_LOCALE = SupportedLocale.EN;

@Injectable({
  providedIn: 'root'
})
export class LocalizationService {
  tokens: Token[] = []
  http = inject(HttpClient)
  currentLocale$: BehaviorSubject<SupportedLocale|null> = new BehaviorSubject<SupportedLocale|null>(null);

  getIsoLocale(): string {
    const locale = this.readCurrentLocale()
    if(locale === SupportedLocale.EN) return 'en-US';
    else if(locale === SupportedLocale.FR) return 'fr-FR';
    else if(locale === SupportedLocale.FR_CA) return 'fr-CA';
    else return locale
  }

  isLocaleSupported(locale: string): boolean {
    return [SupportedLocale.EN.toString(),SupportedLocale.FR.toString(), SupportedLocale.FR_CA.toString()].includes(locale)
  }

  public loadDefaultLocale(): Observable<SupportedLocale>{
    return this.loadLocale(this.readCurrentLocale())
  }

  public loadLocale(locale: SupportedLocale): Observable<SupportedLocale>{
    return this.getTokens$(locale);
  }

  public localize(id: string, ...args: string[]): string { 
    const localizedText = this.tokens?.find(t => t.id === id)?.value || id
    if(args !== undefined ) return this.replacePlaceholders(localizedText, args)
    else return localizedText;
  }

  public isLocalizationToken(locToken: string): boolean{
    return this.tokens.some(t => t.id === locToken);
  }

  private getTokens$(locale: string): Observable<SupportedLocale> {
    if(!this.isLocaleSupported(locale)) return throwError(() => new Error(`Unsupported Locale ${locale}`))
   
    return this.http.get<Locale>(`/assets/locales/messages.${locale}.json`).pipe(
        map(localeJson => localeJson.tokens || []),
        tap(tokens => this.tokens = tokens),
        map(() => locale as SupportedLocale),
        tap(locale => this.currentLocale$.next(locale)),
        // tap(locale => this.saveCurrentLocale(locale)),
        catchError((error) => {
          console.log(`Failed to load localization file messages.${locale}.json`, error)
          return throwError(() => new Error(error))
        })
    )
  }

  private replacePlaceholders(template: string, values: string[]): string {
    let valueIndex = 0;
    return template.replace(/#/g, () => {
      if (valueIndex < values.length) {
        return values[valueIndex++];
      }
      return '#'; // Si le nombre de valeurs est inférieur au nombre de placeholders, on laisse le placeholder
    });
  }

  saveCurrentLocale(locale: SupportedLocale): void {
    return localStorage.setItem('QUANSIC_CURRENT_LOCALE', locale)
  }

  readCurrentLocale(): SupportedLocale {
    return localStorage.getItem('QUANSIC_CURRENT_LOCALE') as SupportedLocale || DEFAULT_LOCALE
  }
}