<mat-toolbar>
  <a mat-button href="javascript:history.go(-1);" [attr.aria-label]="'aria.navigate.to.previous.page'|loc"><mat-icon aria-hidden="true">arrow_back</mat-icon></a>
  <search format="mini" *ngIf="shouldDisplay"></search>
  <span class="spacer"></span>
  <div class="main-title">
    <span style="margin-right: 15px">
      <a [routerLink]="['/app-home']" [attr.aria-label]="'aria.navigate.to.home.page'|loc"><img src="assets/images/quansic_explorer.png" height="32" aria-hidden="true"></a>
    </span>
    <span>
      {{'home.data.explorer.upper'|loc}}
      {{'home.data.explorer.by'|loc}}
      {{'home.data.explorer.quansic'|loc}}
    </span>
    <div *ngIf="isCrtcUser()" class="crtc-edition">
      {{'home.crtc.edition'|loc}}
      <img src="assets/images/canada-flag.png" height="24" [attr.alt]="'aria.alt.canada.flag'|loc">
    </div>
  </div>
  <span class="spacer"></span>
  <button mat-icon-button [matMenuTriggerFor]="menu" [attr.aria-label]="'aria.data.explorer.menu'|loc">
    <mat-icon>menu</mat-icon>
  </button>
  <mat-menu #menu="matMenu" id="main-menu">
    <button mat-menu-item (click)="toggleLang = true" *ngIf="isCrtcUser()">
      <lang-selector [toggle]="toggleLang"></lang-selector>
    </button>
    <button mat-menu-item (click)="openFeedbackDialog()">
      {{'menu.report.error'|loc}}
    </button>
    <button mat-menu-item (click)="openContactUsDialog()">
      {{'menu.contact'|loc}}
    </button>
    <button mat-menu-item (click)="openHelpDialog()">
      {{'menu.help'|loc}}
    </button>
    <button mat-menu-item (click)="openPrivacyPolicyDialog()">
      {{'menu.privacy.policy'|loc}}
    </button>
    <button mat-menu-item (click)="openTermsConditionsDialog()">
      {{'menu.terms.conditions'|loc}}
    </button>
    <button mat-menu-item (click)="logout()">
      {{'menu.logout'|loc}}
    </button>
    <span style="padding-inline: 1.9em; font-size: 0.7em">v{{version}}</span>
  </mat-menu>
</mat-toolbar>
