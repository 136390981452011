
<div class="mat-typography">
  <h1 mat-dialog-title>{{'dialog.privacy.policy.title.1'|loc}}</h1>
  <mat-dialog-content>
    <p>{{'dialog.privacy.policy.paragraph.1'|loc}}</p>
    <p>{{'dialog.privacy.policy.paragraph.2'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.2'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.3'|loc}}
      <a class="link" href="javascript:false;" (click)="showTermsAndConditionsDialog()">{{'dialog.terms.title.1'|loc}}</a>.
    </p>

    <h2>{{'dialog.privacy.policy.title.3'|loc}}</h2>
    <h3>{{'dialog.privacy.policy.title.4'|loc}}</h3>
    <p>{{'dialog.privacy.policy.paragraph.4'|loc}}</p>
    <h3>{{'dialog.privacy.policy.title.5'|loc}}</h3>
    <p>{{'dialog.privacy.policy.paragraph.5'|loc}}</p>
    <h3>{{'dialog.privacy.policy.title.6'|loc}}</h3>
    <p>{{'dialog.privacy.policy.paragraph.6'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.7'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.7'|loc}}</p>
    <p>{{'dialog.privacy.policy.paragraph.8'|loc}}</p>
    <p>{{'dialog.privacy.policy.paragraph.9'|loc}}</p>
    <p>{{'dialog.privacy.policy.paragraph.10'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.8'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.11'|loc}}</p>
    <h3>{{'dialog.privacy.policy.title.9'|loc}}</h3>
    <p>{{'dialog.privacy.policy.paragraph.12'|loc}}</p>
    <h3>{{'dialog.privacy.policy.title.10'|loc}}</h3>
    <p>{{'dialog.privacy.policy.paragraph.13'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.11'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.14'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.12'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.15'|loc}}</p>

    <h2>{{'dialog.privacy.policy.title.13'|loc}}</h2>
    <p>{{'dialog.privacy.policy.paragraph.16'|loc}}</p>
    <p><a href="javascript:false;" (click)="showContactUsDialog()">{{'menu.contact'|loc}}</a></p>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>{{'close'|loc}}</button>
  </mat-dialog-actions>
</div>
