<div class="artist link">
  <a [routerLink]="['/app-party', artist.ids.quansicId]" *ngIf="artist">
    <app-artist-cover [artist]="artist"></app-artist-cover>
    <div class="info">
      <b style="margin-bottom: 5px; display: block">{{artist.name}}</b>
      <em style=" color: white">{{artist.comments}}</em>
      <ul>
        <li>{{'disambig.item.type'|loc}}: {{artist.type}}</li>
        <li>{{'disambig.item.nationality'|loc}}: {{artist.nationality}}</li>
      </ul>
    </div>
  </a>

  <a *ngIf="skeleton" class="artist link" style="width: 320px;">
    <skeleton shape="circle"></skeleton>
    <div class="info">
      <h4 style="margin-bottom: 5px;"><skeleton></skeleton></h4>
      <em style=" color: white"><skeleton shape="tiny"></skeleton></em>
      <ul>
        <li><skeleton shape="tiny"></skeleton></li>
        <li><skeleton shape="tiny"></skeleton></li>
        <li><skeleton shape="tiny"></skeleton></li>
      </ul>
    </div>
  </a>
</div>
