import { Actions, createEffect, ofType } from "@ngrx/effects";
import { map, catchError, of, switchMap, concatMap, tap } from "rxjs";
import { inject } from "@angular/core";
import { ApiSearchService } from "src/app/services/api-search.service";
import { recordingActions } from "./recording.actions";
import { ExplorerUtilsService } from "../../data/services/utils.service";
import { interpretError } from "../utils";
import { ResultService } from "src/app/services/result.service";

export class RecordingEffects {
  actions$ = inject(Actions)
  apiSearchService = inject(ApiSearchService);
  utilsService = inject(ExplorerUtilsService);
  resultService = inject(ResultService);

  getRecordingByIsrc$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recordingActions.getrecordingbyisrc),
      switchMap((action) =>
        this.apiSearchService.lookupIsrc$(action.isrc).pipe(
          map(({xRecording, ySources}) => {
            return recordingActions.getrecordingbyisrcsuccess({ xRecording, ySources })
          }),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(recordingActions.getrecordingsbyartistiderror({ error: interpretError(error) }))
          }))
      ),
    )
  )

  getRecordingsByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recordingActions.getrecordingsbyartistid),
      switchMap((action) =>
        this.apiSearchService.lookupRecordingsByQuansicId(action.quansicId).pipe(
          map(xrecordings => recordingActions.getrecordingsbyartistidsuccess({ quansicId: action.quansicId })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(recordingActions.getrecordingsbyartistiderror({ error }))
          }))
      ),
    )
  )

  getRecordingsCountByArtistId = createEffect(() => 
    this.actions$.pipe(
      ofType(recordingActions.getrecordingscountbyartistid),
      switchMap((action) =>
        this.apiSearchService.lookupRecordingsCountByQuansicId(action.quansicId).pipe(
          map(total => recordingActions.getrecordingscountbyartistidsuccess({ total })),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(recordingActions.getrecordingscountbyartistiderror({ error }))
          }))
      ),
    )
  )

  getRecordingsPageByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recordingActions.getrecordingspagebyartistid),
      switchMap((action) =>
        this.apiSearchService.lookupRecordingsByQuansicIdPaginated(action.quansicId, action.offset).pipe(
          map((recordings) => recordingActions.getrecordingspagebyartistidsuccess({recordings})),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(recordingActions.getrecordingspagebyartistiderror({ error }))
          }))
      ),
    )
  )

  /**
   * When recordings are finished loading, we trigger the paginated fetch of recording contributors
   */
  triggerRecordingContribsByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recordingActions.getrecordingsbyartistidsuccess),
      map(action => recordingActions.getrecordingcontributorspagebyartistid({ quansicId: action.quansicId, offset: 0 }))
    )
  )

  getRecordingContributorsByArtistId$ = createEffect(() =>
    this.actions$.pipe(
      ofType(recordingActions.getrecordingcontributorspagebyartistid),
      concatMap((action) =>
        this.apiSearchService.lookupRecordingContributorsByQuansicIdPaginated(action.quansicId, action.offset).pipe(
          map(({contributors}) => {
            return recordingActions.getrecordingcontributorspagebyartistidsuccess({ contributors})
          }),
          catchError(error => {
            this.utilsService.interceptAuthError(error);
            return of(recordingActions.getrecordingsbyartistiderror({ error }))
          }))
      ),
    )
  )
}
