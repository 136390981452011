import { DialogUtilsService } from './../shared/data/services/dialog-utils.service';
import { CatalogService } from '../services/firestore/catalog.firestore.service';
import { Component, Inject, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Router} from "@angular/router";
import { EmailService} from "../services/email.service";
import { ExplorerRole} from '@githubquansic/auth/built/client';
import { LoginService, UserData } from '@githubquansic/web-auth/ng';
import { Observable, of} from "rxjs";
import { environment } from "../../environments/environment";
import { DOCUMENT } from '@angular/common';
import { InlineEditService } from '../services/inline-edit.service';
import { ResultService } from '../services/result.service';
import { CrtcService } from '../crtc/services/crtc.service';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent {
  dialogUtilsService = inject(DialogUtilsService)

  userData: UserData|null = null;
  version: string = environment.version;
  isDataTest = false;
  isDev = false;

  shouldDisplay = true;
  
  toggleLang:boolean = false

  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;

  constructor(@Inject(DOCUMENT) private document: Document,
    private loginService: LoginService,
    private inlineEditService: InlineEditService,
    private crtcService: CrtcService,
    public catalogService: CatalogService,
    public resultService: ResultService,
    private emailService: EmailService,
    private router: Router,
    private route: ActivatedRoute) {
    this.userData = this.loginService.getUserData() ;
    this.isDataTest = environment.environmentName === "data-test";
    this.isDev = environment.environmentName === "development";

    this.evaluateContext();
  }

  evaluateContext() {
    this.shouldDisplay = !(this.route.snapshot.url[0]?.path === "home" && this.route.snapshot.url[1]?.path === "any")
  }

  openContactUsDialog(): void {
    this.dialogUtilsService.openContactUsDialog(this.menuTrigger);
  }

  openPrivacyPolicyDialog(): void {
   this.dialogUtilsService.openPrivacyPolicyDialog(this.menuTrigger);
  }

  openTermsConditionsDialog(): void {
    this.dialogUtilsService.openTermsConditionsDialog(this.menuTrigger);
  }

  openHelpDialog(): void {
    this.dialogUtilsService.openHelpDialog(this.menuTrigger);
  }

  openFeedbackDialog(): void {
    this.dialogUtilsService.openFeedbackDialog(this.menuTrigger);
  }


  logout(): void {
    this.loginService.logout().subscribe();
    this.router.navigate(['/app-login']);
  }

  contactUsCallback(contactData: any): Observable<any> {
    return this.emailService.contactMessage(contactData);
  }

  isEditModeEnabled() {
    return this.loginService.getUserData()?.rights.explorerRoles.includes(ExplorerRole.Writer)
  }

  isCrtcUser() {
    return this.crtcService.isCrtcUser();
  }

  toggleEditMode(){
    if(this.inlineEditService.editMode) {
      this.inlineEditService.openConfirmDialog()
    } else this.inlineEditService.enableEditMode();
  }

  isEditModeSliderChecked$ = this.inlineEditService.editMode$
  isEditModeSliderDisabled$ = of(false);//this.catalogService.isCurrentInCatalog$.pipe(map(isInCatalog => !isInCatalog));
}
