import { HttpClient, HttpHeaders } from "@angular/common/http";
import { inject, Injectable } from "@angular/core";
import { Observable, from, map, of, switchMap, tap } from "rxjs";
import { SpotifyArtist } from "../model";
import { HttpHelperService } from "./http.service";

@Injectable({
    providedIn: 'root'
})
export class SpotifyService {
    readonly http = inject(HttpClient);
    readonly httpHelperService = inject(HttpHelperService);

    private bffApiUrl = this.httpHelperService.getBffDomain();  // URL to web api

    accessToken$: Observable<SpotifyAccessToken|null> = of(null);
    lastTokenTime: number = 0;

    getSpotifyAuthenticationToken$(): Observable<SpotifyAccessToken> {
        return from(this.accessToken$)
            .pipe(
                switchMap((accessToken) => {
                    if(!accessToken || this.isExpired(accessToken)) return this.fetchAuthToken$()
                    else return of(accessToken)
                })
            )
    }

    fetchAuthToken$(): Observable<SpotifyAccessToken> {
        return this.http.get<SpotifyAccessToken>(`${this.bffApiUrl}/api/spotify/token`)
        .pipe(tap(() => this.lastTokenTime = this.now()))
    }

    getSpotifyArtist$(spotifyId: string): Observable<SpotifyArtist> {
        return this.getSpotifyAuthenticationToken$().pipe(
            switchMap(accessToken => {
                return this.http.get<SpotifyArtist>(
                    `${this.bffApiUrl}/api/spotify/artist/${encodeURIComponent(spotifyId)}?accessToken=${encodeURIComponent(accessToken.access_token)}`
                )
            })
        )
    }

    now(): number {
        return Date.now() * 1000
    }

    isExpired(accessToken: SpotifyAccessToken): boolean {
        return this.now() - this.lastTokenTime > accessToken.expires_in
    }

    getSpotifyImageLink(spotifyId: string): Observable<string>{
        return this.getSpotifyArtist$(spotifyId).pipe(
            map(spotifyArtist => spotifyArtist.images[0].url)
        )
    }
}

export type SpotifyAccessToken = {
    access_token: string,
    token_type: string;
    expires_in: number;
}