<div class="entity-page">
  <div class="name-block">
    <h1 loc="recording"></h1>
    <div class="name">
      <h1><skeleton shape="small"></skeleton></h1>
    </div>
  </div>
  <div class="info">
    <div class="picture">
      <div>
        <skeleton shape="circle"></skeleton>
      </div>
    </div>
    <div style="flex: 0 1 1em"></div>
    <div class="information">
      <h2 loc="information"></h2>
      <div><span class="strong">{{'recording.duration'|loc}}</span> <skeleton shape="small"></skeleton></div>
      <div><span class="strong">{{'recording.year'|loc}}</span> <skeleton shape="small"></skeleton></div>
      <div><span class="strong">{{'recording.country'|loc}}</span> <skeleton shape="small"></skeleton></div>
    </div>
    <div class="identifiers">
      <h2>Identifiers</h2>
      <div class="identifiers-list">
        <div class="identifier"><span class="strong">{{'recording.isrc'|loc}}</span> <skeleton shape="small"></skeleton></div>
        <div class="identifier"><span class="strong">{{'recording.spotifyTrackId'|loc}}</span> <skeleton shape="small"></skeleton></div>
        <div class="identifier"><span class="strong">{{'recording.appleTrackId'|loc}}</span> <skeleton shape="small"></skeleton></div>
      </div>
    </div>
    <div class="crtc" *ngIf="isCrtcUser">
      <h2 loc="recording.crtc"></h2>
      <div><div class="strong" loc="recording.crtc.maipl.score"></div><skeleton shape="small"></skeleton></div>
      <div><div class="strong" loc="recording.crtc.is.cancon"></div><skeleton shape="small"></skeleton></div>
      <div><div class="strong" loc="recording.crtc.language"></div><skeleton shape="small"></skeleton></div>
      <div><div class="strong"  loc="recording.crtc.sub.category"></div><skeleton shape="small"></skeleton></div>
      <div><div class="strong"  loc="recording.crtc.is.emerging"></div><skeleton shape="small"></skeleton></div>
    </div>
  </div>
  <div class="lists">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start">
      <mat-tab [label]="'recording.main.artists'|loc">
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="releases"></div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div loc="works"></div>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
