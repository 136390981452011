/* Web app model */

export type EntityType = "party"|"recording"|"release"|"work"

export interface Entity {
  id: string,
  entityType: EntityType
}
export interface Artist extends Entity{
  id: string,
  name: string,
  firstname?: string,
  lastname?: string,
  ids: Identifiers,
  type?: string,
  comments?: string,
  role?: string,
  cover?: string,
  popularity?: number,
  birthdate?: string,
  deathdate?: string,
  nationality: string,
  relationships?: {
    isMemberOf: Artist[],
    hasMember: Artist[],
    aka: Artist[]
  },
  namevariants? : NameVariant[],
  contributorType?: string
  ySources: YSources
}

export interface Release extends Entity{
  upc?: string,
  type: string,
  title: string,
  year?: string,
  country: string,
  cover: string,
  coverImage: string,
  label: string,
  active?: boolean
}

export interface Recording extends Entity{
  isrc: string,
  appleId: string,
  spotifyId: string,
  title: string,
  subtitle: string,
  year?: number,
  country?: string,
  contributorType?: string,
  contributors?: Artist[],
  mainArtists?: Artist[],
  performers?: Artist[],
  duration: number,
  audioUrl: string,
  pLine: string,
  role?: string,
  active?: boolean
  crtcMaiplStatus?: XCrtcMaiplStatus,
  crtcPropertiesStatus: XCrtcProperties, 
  ySources: YSources
}

export interface RecordingContributor {
  isrc: string;
  mainArtists: Artist[];
}


export interface RecordingOfWork extends Recording {
  q2Score?: number;
}

export interface Work extends Entity{
  iswc?: string,
  bowi?: string,
  title: string,
  subtitle?: string,
  role?: string,
  contributors?: Artist[],
  recordings: Recording[]
}
export interface WorkOfRecording extends Work {
  q2Score?: number;
}

export interface Indexable<T = any> {
  [key: string]: T
}

export interface Identifier {
  type: string,
  value: string
}

export interface Identifiers {
  quansicId:string,
  isnis: string[],
  mergedIsnis: string[],
  ipis: string[],
  ipns: string[],
  musicBrainzIds: string[],
  appleIds: string[],
  spotifyIds: string[],
  wikidataIds: string[],
  discogsIds: string[],
  amazonIds: string[],
  deezerIds: string[],
  luminateIds: string[],
  gracenoteIds: string[],
  tmsIds: string[],
}

export interface Relationship {
  name: string,
  ids: object,
  type: string,
  relationshipType: string,
  identifier: Identifier
}
export interface Relationships {
  hasMember: any[]
  isMemberOf: any[]
  aka: any[]
}

export interface NameVariant {
  fullname: string,
  language?: string
}

/* X API data model */
export interface XApiResponse {
  status: string,
  message?: string,
  results: {
    releases?: XAPIRelease[]
    release?: XAPIRelease,
    party?: XAPIParty,
    parties?: XAPIParty[],
    recordings?: XAPIRecording[],
    recording?: XAPIRecording,
    work?: XAPIWork,
    stats?:XApiStats
  }
}
export interface YApiResponse {
  status: string,
  message?: string
  results: any
}
export interface QApiResponse {
  status: string,
  message?: string,
  results: {
    total?: number
    recordings?: QAPIRecording[],
  }
}
export interface XAPIRelease {
  upc: string,
  type: string,
  title: string,
  cover?: string,
  year?: string,
  recordings?: XAPIRecording[],
  parties?: XAPIParty[],
  label?: string,
  active?: boolean
}

export interface XAPIRecording {
  isrc: string,
  appleId?: string,
  spotifyId?: string,
  title: string,
  subtitle: string,
  durationMs: number,
  audioUrl: string,
  year: number,
  country: string,
  releases?: XAPIRelease[],
  works?: XAPIWork[],
  contributors?: XAPIParty[],
  contributorType?: string,
  role?: string,
  pLine?: string,
  active: boolean,
  q2Score?: number
  crtcMaiplStatus?: XCrtcMaiplStatus
  crtcPropertiesStatus?: XCrtcProperties
}
export type XCrtcMaiplStatus = {
  isCanadianContent: boolean
  source: string,
  classifications: {
    [index: string]: string;
  }
}

export type XCrtcProperties = {
  language: string|null,
  subCategory: string|null,
  isEmerging: boolean|null,
}


export type YSources = {[key:string]: string[]}
// export type YSourcePayload = {id: string, source: string}

export interface XAPIWork {
  iswc: string,
  bowi?: string,
  title: string,
  subtitle?: string,
  role: string,
  contributors?: XAPIParty[],
  undefinedContributors?: XAPIParty[],
  recordings?: XAPIRecording[],
  q2Score?: number
}

export interface XAPIParty {
  name: string,
  firstname?: string,
  lastname?: string,
  ids: Identifiers,
  popularity?: number,
  birthdate?: string,
  deathdate?: string,
  nationality?: string,
  type?: string,
  comment?: string,
  visual?: string
  role?: string,
  relationships?: [],
  recordings?: [],
  releases?: [],
  works?: [],
  nameVariants?: NameVariant[],
  contributorType?: string,
  relationshipType?: string
}

export interface XApiStats {
  parties: number|null,
  partyIds: number|null,
  recordings: number|null,
  releases: number|null,
  works: number|null,
  totalAssets: number|null
}

export interface LoginAPIResponse {
  token: string
  userData: UserData
}
export interface JWTVerifyResponse {
  exp: number,
  iat: number,
  data: UserData
}

export interface UserData {
  id: string,
  company: string,
  email: string,
  name: string,
  role: string,
  apiKey:string
}

export interface Result {
  error: ApiError|null;
  errorMsg: string|null;
  errorId?: {
    idType: string,
    id: string
  };
  isShowDisambig: boolean;
  nameDisambigQuery: string;
  artistsForDisambiguation: Artist[];
  artists: Artist[];
  releases: Release[];
  recordings: Recording[];
  works: Work[];
  selectedArtist: Artist|null;
  selectedRecording: Recording|null;
  selectedRelease: Release|null;
  selectedWork: Work|null;
}

export interface ArtistResult {
  artist: Artist,
  releases: Release[],
  recordings: Recording[]
}

export enum ApiError {
  NOT_FOUND = 'NOT_FOUND',
  INVALID_ID= 'INVALID_ID',
  SERVER_ERROR = 'SERVER_ERROR',
  UNAUTHORIZED = 'UNAUTHORIZED',
  NO_MATCH = 'NO_MATCH',
  UNKNOWN = 'UNKNOWN',
}

export interface Progress {
  queryInProgress?: boolean,
  playbackInProgress?: boolean,
  trackIsrc?: string
}

export enum SelectCriteria {
  NAME = 'name',
  ISNI = 'isni',
  IPI = 'ipi',
  IPN = 'ipn',
  DISCOGS_ID = 'discogs-id',
  MUSICBRAINZ_ID = "musicBrainz-id",
  APPLE_ID = 'apple-id',
  SPOTIFY_ID = 'spotify-id',
  WIKIDATA_ID = 'wikidata-id',
  AMAZON_ID = 'amazon-id',
  ISRC = 'isrc',
  ISWC = 'iswc',
  BOWI = 'bowi',
  UPC = 'upc',
  DEEZER_ID = 'deezer-id',
  LUMINATE_ID = 'luminate',
  GRACENOTE_ID = 'gracenote',
  TMS_ID = 'tms',
}


export type SkeletonResult<T> =
{
  status: 'loading'|'error'
}
|{
  status: 'success'
  data: T
}

export type QAPIRecording = {
  isrc: string
  title: string
  subtitle: string
  audioUrl: string
  year: number
}

export enum ArtistLockAction{
  Lock='Lock',
  Unlock='Unlock'
}

export enum ArtistLockStatus{
  Locked='Locked',
  Unlocked='Unlocked'
}

export type SpotifyArtist = {
  "external_urls": {
    "spotify": string
  },
  "followers": {
    "href": "string",
    "total": 0
  },
  "genres": string[],
  "href": "string",
  "id": "string",
  "images": [
    {
      "url": string,
      "height": number,
      "width": number
    }
  ],
  "name": "string",
  "popularity": 0,
  "type": "artist",
  "uri": "string"
}