import { Component, inject, Input, OnChanges, SimpleChanges } from "@angular/core";
import { LocalizationService } from "../../localization/localization.service";
import { DialogUtilsService } from "../../data/services/dialog-utils.service";

@Component({
  selector: 'table-empty',
  templateUrl: './table-empty.component.html',
  styleUrls: ['./table-empty.component.css']
})

export class TableEmptyComponent implements OnChanges{
  private readonly localizationService = inject(LocalizationService);
  private readonly dialogUtils = inject(DialogUtilsService);

  @Input('entity') entity!: string;

  noEntityLabel!: string;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['entity']) {
      this.noEntityLabel = this.localizationService.localize(`no.${this.entity}.yet`);
    }
  }

  openFeedbackDialog(): void {
    this.dialogUtils.openFeedbackDialog(null);
  }
}
