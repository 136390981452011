<div class="mat-typography">
  <h2 mat-dialog-title>{{'dialog.export.title'|loc}} {{data.artist.name}}</h2>
  <div mat-dialog-content>

    <div style="line-height: 40px" [loc]="'dialog.export.format'"></div>
  </div>
  <div mat-dialog-actions align='end'>
    <button mat-button mat-dialog-close>{{'close'|loc}}</button>
    <button mat-raised-button color="accent" (click)="exportAsExcel()" [disabled]="!canExportExcel()">Excel</button>
    <button mat-raised-button color="accent" (click)="exportAsCSV()" [disabled]="!canExportCSV()">CSV</button>
    <button mat-raised-button color="accent" (click)="exportAsJson()" [disabled]="!canExportJson()">JSON</button>
  </div>
</div>
