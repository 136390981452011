import { Component, inject, Input, OnChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { EMPTY, Observable, of } from "rxjs";
import { DialogCoverComponent } from "src/app/dialog-cover/dialog-cover.component";
import { Artist } from "src/app/model";
import { ImageService } from "src/app/services/image.service";
import { LocalizationService } from "src/app/shared/localization/localization.service";

@Component({
    selector: 'app-artist-cover',
    templateUrl: './artist-cover.component.html',
    styleUrls: ['./artist-cover.component.scss']
})
export class ArtistCoverComponent implements OnChanges{
    readonly dialog = inject(MatDialog);
    readonly imageService = inject(ImageService);
    readonly localizationService = inject(LocalizationService);

    @Input() artist!: Artist;

    defaultArtistCover = '/assets/images/artist_default.jpg';
    coverImage$: Observable<string> = EMPTY;
    
    constructor() {
        this.coverImage$ = this.buildImagePath$();
    }

    ngOnChanges(): void {
        this.coverImage$ = this.buildImagePath$();
    }

    openCoverDialog() {
        this.imageService.convertImagePath$(this.artist.cover, this.artist.ids.spotifyIds[0])
            .subscribe(imagePath => {
                if(this.artist?.cover === undefined) return
                this.dialog.open(DialogCoverComponent, {
                    width: '45vw',
                    data: {
                        title: this.artist.name,
                        cover: imagePath,
                        originalUrl: this.artist.cover,
                    }
                })
            })
    }

    buildImagePath$(): Observable<string> {
        if(this.artist && this.artist.cover !== undefined) {
            return  this.imageService.convertImagePath$(this.artist.cover, this.artist.ids.spotifyIds[0]) 
        }
        else return of(this.imageService.defaultArtistCover);
    }    
    
    getArtistCoverAlt(): string {
        return `${this.localizationService.localize('artist.cover.image')} ${this.artist.name}`;
    }
}