import { Component, inject, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { LocalizationService } from "../shared/localization/localization.service";

@Component({
  templateUrl: './dialog-additional-ids.component.html',
  styleUrls: ['./dialog-additional-ids.component.scss']
})
export class DialogAdditionalIdsComponent {
  private readonly localizationService = inject(LocalizationService);

  title!: string

  constructor(
    public dialogRef: MatDialogRef<DialogAdditionalIdsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any){  

    this.title = this.localizationService.localize("dialog.additional.ids.title", data.idName, data.identifiers.length);
  }

    
}
